<div class="termination-formula-list-component">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Matching Termination</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table class="table">
                <thead>
                    <tr>
                        <td>Id</td>
                        <td>Name</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let terminationFormula of terminationFormulas">
                        <td>{{ terminationFormula.id }}</td>
                        <td>{{ terminationFormula.name }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</div>