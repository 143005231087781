import { Injectable } from '@angular/core';

import {
    DialDataUpdatedEventEmitter,
    IsLoadingEventEmitter,
    ProjectEventEmitter,
    ProjectSelectedEventEmitter,
    TraceItemSelectionChangedEventEmitter,
    UserEventEmitter
} from '../event-emitters';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PubSubService {

    DialDataUpdatedChannel: DialDataUpdatedEventEmitter;
    IsLoadingChannel$: BehaviorSubject<boolean>;
    ProjectChannel: ProjectEventEmitter;
    ProjectSelectedChannel: ProjectSelectedEventEmitter;
    TraceItemSelectionChangedChannel: TraceItemSelectionChangedEventEmitter;
    UserChannel: UserEventEmitter;

    constructor() {
        this.DialDataUpdatedChannel = new DialDataUpdatedEventEmitter({ projectId: 0, episodeId: 0, traceCollection: null });
        this.IsLoadingChannel$ = new BehaviorSubject<boolean>(false);
        this.ProjectChannel = new ProjectEventEmitter();
        this.ProjectSelectedChannel = new ProjectSelectedEventEmitter({ project: null, series: null, survey: null });
        this.TraceItemSelectionChangedChannel = new TraceItemSelectionChangedEventEmitter({ traceItems: [] });
        this.UserChannel = new UserEventEmitter();
        console.log("PubSubService Constructor");
    }
}
