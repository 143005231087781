import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuardService } from './core/services';
import { Roles } from './core/models/roles.model';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin, Roles.User]
                },
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'admin',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin]
                },
                loadChildren: () => import ('./admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'projects',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin, Roles.ProjectViewer, Roles.ProjectEditor]
                },
                loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule)
            },
            {
                path: 'surveys',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin, Roles.ProjectViewer, Roles.ProjectEditor]
                },
                loadChildren: () => import ('./surveys/surveys.module').then(m => m.SurveysModule)
            },
            {
                path: 'series',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin, Roles.ProjectViewer, Roles.ProjectEditor]
                },
                loadChildren: () => import('./series/series.module').then(m => m.SeriesModule)
            },
            {
                path: 'assignments',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin, Roles.FacilityViewer, Roles.FacilityEditor]
                },
                loadChildren: () => import('./assignments/assignments.module').then(m => m.AssignmentsModule)
            },
            {
                path: 'mapping',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super]
                },
                loadChildren: () => import('./mapping/mapping.module').then(m => m.MappingModule)
            },
            {
                path: 'monitor',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin, Roles.Monitor]
                },
                loadChildren: () => import('./monitor/monitor.module').then(m => m.MonitorModule)
            },
            {
                path: 'online-monitor',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin, Roles.Monitor]
                },
                loadChildren: () => import('./online-monitor/online-monitor.module').then(m => m.OnlineMonitorModule)
            },
            {
                path: 'course5',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super]
                },
                loadChildren: () => import('./course5/course5.module').then(m => m.Course5Module)
            },
            {
                path: 'analysis',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin, Roles.ResultsViewer, Roles.ResultsEditor]
                },
                loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisModule)
            },
            {
                path: 'test',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super]
                },
                loadChildren: () => import ('./test/test.module').then(m => m.TestModule)
            },
            {
                path: 'participants',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super]
                },
                loadChildren: () => import ('./participants/participants.module').then(m => m.ParticipantsModule)
            },
            {
                path: 'users',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super]
                },
                loadChildren: () => import ('./users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'templates',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin]
                },
                loadChildren: () => import ('./templates/templates.module').then(m => m.TemplatesModule)
            },
            {
                path: 'norms',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin, Roles.ResultsViewer]
                },
                loadChildren: () => import ('./norms/norms.module').then(m => m.NormsModule)
            },
            {
                path: 'import',
                canActivate: [AuthGuardService],
                data: {
                    roles: [Roles.Super, Roles.Admin]
                },
                loadChildren: () => import ('./import/import.module').then(m => m.ImportModule)
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
        }]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
