import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class TypedApiService {

    private defaultOptions = {
        headers: { "Content-Type": "application/json" }
    };

    constructor(private _httpClient: HttpClient) { }

    get<T>(path: string, options: Object = null): Observable<T> {
        return this._httpClient
            .get<T>(
                `${environment.apiRoot}${path}`,
                (options) ? options : this.defaultOptions
            )
            .pipe(catchError(this.formatErrors));
    }

    post<T>(path: string, body: Object = {}): Observable<T> {
        return this._httpClient
            .post<T>(
                `${environment.apiRoot}${path}`,
                JSON.stringify(body),
                this.defaultOptions
            )
            .pipe(catchError(this.formatErrors));
    }

    put<T>(path: string, body: Object = {}): Observable<T> {
        return this._httpClient
            .put<T>(
                `${environment.apiRoot}${path}`,
                JSON.stringify(body),
                this.defaultOptions
            )
            .pipe(catchError(this.formatErrors));
    }

    download(path: string): Observable<HttpResponse<Blob>> {
        //const fullOptions = Object.assign({}, this.defaultOptions, { observe: 'response' });
        return this._httpClient.get<Blob>(`${environment.apiRoot}${path}`, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    upload(path: string, file: File): Observable<any> {
        const formData = new FormData();
        formData.append("file", file);

        return this._httpClient.post(`${environment.apiRoot}${path}`, formData, {
            /*headers: { "Content-Type": "multipart/form-data" },*/
            reportProgress: true,
            observe: 'events'
        });
    }

    ///////////////////////////////////

    private formatErrors(error: HttpErrorResponse) {
        return throwError(error.error);
    }
}
