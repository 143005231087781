<div class="participant-screener-list-component">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Screener</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div *ngFor="let screener of screenerObjects$ | async">
                <table class="table table-sm">
                    <tbody>
                        <tr *ngFor="let item of screener | keyvalue">
                            <td>{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
</div>