import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy', pure: true })
export class OrderByPipe implements PipeTransform {
    transform(value: any[], propertyName: string): any[] {
        if (propertyName) {
            return value.sort((a: any, b: any) => {
                if (b[propertyName] == a[propertyName])
                    return 0;
                else if (b[propertyName] > a[propertyName])
                    return -1;
                else
                    return 1;
            });
        }
        else {
            return value;
        }
    }
}