import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ProjectSummary } from '@app/core/models';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectListComponent {

    constructor() {
        this.dataSource = new MatTableDataSource<ProjectSummary>([]);
    }

    displayedColumns: string[] = ['id', 'surveyProviderName', 'code', 'name', 'surveyName'];
    dataSource: MatTableDataSource<ProjectSummary>;

    @Input()
    set projectSummaries(projectSummaries: ProjectSummary[]) {
        this.dataSource.data = projectSummaries;
    }

    get projectSummaries(): ProjectSummary[] {
        return this.dataSource.data;
    }

    @Output() projectSelected = new EventEmitter<number>();

    @ViewChild(MatTable, { static: true }) table: MatTable<ProjectSummary>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    goToProjectDetail(id: number) {
        this.projectSelected.emit(id);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
}
