import { Roles } from '@app/core/models/roles.model';

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    roles: string[];
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'dashboard',
        roles: [Roles.Admin, Roles.User]
    },
    {
        path: '/admin',
        title: 'Admin',
        type: 'link',
        icontype: 'dashboard',
        roles: [Roles.Super, Roles.Admin]
    },
    {
        path: '/projects',
        title: 'Projects',
        type: 'link',
        icontype: 'list',
        roles: [Roles.Super, Roles.Admin, Roles.ProjectViewer, Roles.ProjectEditor]
    },
    {
        path: '/surveys',
        title: 'Surveys',
        type: 'link',
        icontype: 'list',
        roles: [Roles.Super, Roles.Admin, Roles.ProjectViewer, Roles.ProjectEditor]
    },
    {
        path: '/series',
        title: 'Series',
        type: 'link',
        icontype: 'theaters',
        roles: [Roles.Super, Roles.Admin, Roles.ProjectViewer, Roles.ProjectEditor]
    },
    {
        path: '/assignments',
        title: 'Assignment',
        type: 'link',
        icontype: 'content_paste',
        roles: [Roles.Super, Roles.Admin, Roles.FacilityViewer, Roles.FacilityEditor]
    },
    {
        path: '/mapping',
        title: 'Norms Mapping',
        type: 'link',
        icontype: 'sync_alt',
        roles: [Roles.Super]
    },
    {
        path: '/monitor',
        title: 'Monitor',
        type: 'link',
        icontype: 'search',
        roles: [Roles.Super, Roles.Admin, Roles.Monitor]
    },
    {
        path: '/online-monitor',
        title: 'Online Monitor',
        type: 'link',
        icontype: 'search',
        roles: [Roles.Super, Roles.Admin, Roles.Monitor]
    },
    {
        path: '/course5',
        title: 'Course5 Api',
        type: 'link',
        icontype: 'vpn_key',
        roles: [Roles.Super]
    },
    {
        path: '/analysis',
        title: 'Analysis',
        type: 'link',
        icontype: 'timeline',
        roles: [Roles.Super, Roles.Admin, Roles.ResultsViewer, Roles.ResultsEditor]
    },
    {
        path: '/import',
        title: "Import",
        type: 'link',
        icontype: 'login',
        roles: [Roles.Super, Roles.Admin]
    },
    {
        path: '/participants',
        title: 'Participants',
        type: 'link',
        icontype: 'timeline',
        roles: [Roles.Super]
    },
    {
        path: '/test',
        title: 'Test',
        type: 'link',
        icontype: 'timeline',
        roles: [Roles.Super]
    },
    {
        path: '/users',
        title: 'Users',
        type: 'link',
        icontype: 'user',
        roles: [Roles.Super]
    },
    {
        path: '/templates',
        title: 'Templates',
        type: 'link',
        icontype: 'user',
        roles: [Roles.Super]
    },
    {
        path: '/norms',
        title: 'Norms',
        type: 'link',
        icontype: 'user',
        roles: [Roles.Super]
    }
];
