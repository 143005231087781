<div class="quota-list-component">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Matching Quotas</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table class="table">
                <thead>
                    <tr>
                        <td>Id</td>
                        <td>Description</td>
                        <td>Sex</td>
                        <td>Min Age</td>
                        <td>Max Age</td>
                        <td>Progress</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let quota of quotas">
                        <td>{{ quota.id }}</td>
                        <td>{{ quota.description }}</td>
                        <td>{{ quota.sex }}</td>
                        <td>{{ quota.minimumAge }}</td>
                        <td>{{ quota.maximumAge }}</td>
                        <td>{{ quota.currentCount }} / {{ quota.maximumCount }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</div>