<div class="card project-list-component">
    <div class="card-header card-header-danger">
        <h4 class="card-title ">Project List</h4>
    </div>
    <div class="card-body">
        <mat-form-field>
            <input #filter matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" autocomplete="off">
            <button mat-button *ngIf="dataSource.filter" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filter='';filter.value=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                <td mat-cell *matCellDef="let project"> {{project.id}} </td>
            </ng-container>

            <ng-container matColumnDef="surveyProviderName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Provider </th>
                <td mat-cell *matCellDef="let project"> {{project.surveyProviderName}} </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
                <td mat-cell *matCellDef="let project"> {{project.code}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let project"> {{project.name}} </td>
            </ng-container>

            <ng-container matColumnDef="surveyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Survey </th>
                <td mat-cell *matCellDef="let project"> {{project.surveyName}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let project; columns: displayedColumns;" style="cursor: pointer;"
                        (click)="goToProjectDetail(project.id)">
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
</div>
