import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TerminationFormula } from '@app/core/models';

@Component({
    selector: 'app-termination-formula-list',
    templateUrl: './termination-formula-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminationFormulaListComponent {
    @Input() terminationFormulas: TerminationFormula[];
}
