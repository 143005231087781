import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { UserService } from './user.service';
import { User } from '../models';

export function initializeApp(appInitService: AppInitializerService) {
    return (): Promise<any> => {
        return appInitService.Init();
    }
}

@Injectable({ providedIn: 'root' })
export class AppInitializerService {

    constructor(private userService: UserService) { }

    Init() {
        return new Promise<void>((resolve, reject) => {
            this.userService
                .populate()
                .pipe(take(1))
                .subscribe((user: User) => {
                    if (user) {
                        console.log('user is valid');
                    } else {
                        console.log('not valid');
                    }
                    resolve();
                });
        });
    }
}
