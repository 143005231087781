import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { map, distinctUntilChanged, catchError } from 'rxjs/operators';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class UserService {

    private currentUserSubject = new BehaviorSubject<User>({} as User);
    public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    public isAuthenticated = this.isAuthenticatedSubject.asObservable();

    constructor(
        private apiService: ApiService,
        private jwtService: JwtService
    ) { }

    // Verify JWT in localstorage with server & load user's info.
    // This runs once on application startup.
    populate(): Observable<User | null> {
        // If JWT detected, attempt to get & store user's info
        if (this.jwtService.getToken()) {
            return this.apiService
                .get('account')
                .pipe(
                    map(data => {
                        this.setAuth(data);
                        return data;
                    }),
                    catchError(err => {
                        this.purgeAuth();
                        return null;
                    })
                );
        } else {
            // Remove any potential remnants of previous auth states
            this.purgeAuth();
            return of(null);
        }
    }

    setAuth(user: User) {
        // Save JWT sent from server in localstorage
        this.jwtService.saveToken(user.token);
        // Set current user data into observable
        this.currentUserSubject.next(user);
        // Set isAuthenticated to true
        this.isAuthenticatedSubject.next(true);
    }

    purgeAuth() {
        // Remove JWT from localstorage
        this.jwtService.destroyToken();
        // Set current user to an empty object
        this.currentUserSubject.next({} as User);
        // Set auth status to false
        this.isAuthenticatedSubject.next(false);
    }

    attemptAuth(credentials): Observable<(User & { error: string })> {
        return this.apiService
            .post('account', credentials)
            .pipe(map(
                data => {
                    if (!data.error) {
                        this.setAuth(data);
                    }
                    return data;
                }
            ));
    }

    getCurrentUser(): User {
        return this.currentUserSubject.value;
    }

    // Update the user on the server (email, pass, etc)
    update(user): Observable<User> {
        return this.apiService
            .put('/user', { user })
            .pipe(map(data => {
                // Update the currentUser observable
                this.currentUserSubject.next(data.user);
                return data.user;
            }));
    }
}
