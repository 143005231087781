import { Injectable } from '@angular/core';

import { Question, Banner, AgeBreak, QuestionForFormula, ParticipantSet, TableSetInputModel, TableSettings, SimpleFormulaDetail, Episode } from '../models';

@Injectable({ providedIn: 'root' })
export class CommonService {

    constructor() { }

    cloneQuestions(questionsToClone: Question[]): Question[] {
        const questionsToReturn: Question[] = [];

        questionsToClone.forEach(q => {
            questionsToReturn.push(this.cloneQuestion(q));
        });

        return questionsToReturn;
    }

    cloneQuestion(questionToClone: Question): Question {
        let questionToReturn: Question = Object.assign({}, questionToClone);
        questionToReturn.choices = [];

        if (questionToClone.choices) {
            questionToClone.choices.forEach(c => {
                questionToReturn.choices.push(Object.assign({}, c));
            });
        }

        return questionToReturn;
    }

    cloneBanners(bannersToClone: Banner[]): Banner[] {
        const bannersToReturn: Banner[] = [];

        bannersToClone.forEach(b => {
            bannersToReturn.push(Object.assign({}, b));
        });

        return bannersToReturn;
    }

    cloneAgeBreaks(ageBreaksToClone: AgeBreak[]): AgeBreak[] {
        const ageBreaksToReturn: AgeBreak[] = [];

        ageBreaksToClone.forEach(a => {
            ageBreaksToReturn.push(Object.assign({}, a));
        });

        return ageBreaksToReturn;
    }

    cloneParticipantSets(setsToClone: ParticipantSet[]): ParticipantSet[] {
        const setsToReturn: ParticipantSet[] = [];

        setsToClone.forEach(s => {
            setsToReturn.push(Object.assign({}, s));
        });

        return setsToReturn;
    }

    cloneTableSetInputModel(tableSetToClone: TableSetInputModel): TableSetInputModel {
        if (!tableSetToClone)
            return null;

        return {
            id: tableSetToClone.id,
            projectId: tableSetToClone.projectId,
            name: tableSetToClone.name,
            isPublic: tableSetToClone.isPublic,
            isDefault: tableSetToClone.isDefault,
            tableSettings: this.cloneTableSettings(tableSetToClone.tableSettings)
        };
    }

    cloneTableSettings(tableSettings: TableSettings): TableSettings {
        if (!tableSettings)
            return null;

        return {
            projectId: tableSettings.projectId,
            participantSets: [...tableSettings.participantSets],
            questions: [...tableSettings.questions],
            ageBreaks: [...tableSettings.ageBreaks],
            banners: [...tableSettings.banners],
            crosstabDetails: this.cloneSimpleFormulaDetails(tableSettings.crosstabDetails),
            filter: this.cloneSimpleFormulaDetails(tableSettings.filter),
            includeSex: tableSettings.includeSex,
            includeSexAndAge: tableSettings.includeSexAndAge,
            byParticipantSet: tableSettings.byParticipantSet,
            asFrequencies: tableSettings.asFrequencies
        };
    }

    cloneSimpleFormulaDetails(formulasToClone: SimpleFormulaDetail[]): SimpleFormulaDetail[] {
        const formulasToReturn: SimpleFormulaDetail[] = [];

        if (formulasToClone && formulasToClone.length > 0) {
            formulasToClone.forEach(x => {
                formulasToReturn.push(Object.assign({}, x));
            });
        }

        return formulasToReturn;
    }

    questionToQuestionForFormula(question: Question): QuestionForFormula {
        const questionForFormula: QuestionForFormula = {
            providerQuestionId: question.providerQuestionId,
            label: question.label,
            choices: question.choices.map(c => {
                return { providerRecode: c.providerRecode, text: c.text };
            })
        };

        return questionForFormula;
    }

    getDistinctEpisodesFromParticipantSets(participantSets: ParticipantSet[]): Episode[] {
        const episodesToReturn: Episode[] = [];

        if (participantSets) {
            participantSets.forEach(x => {
                if (x.episodes) {
                    x.episodes.forEach(e => {
                        if (!episodesToReturn.find(y => y.id === e.id)) {
                            episodesToReturn.push(e);
                        }
                    });
                }
            });
        }

        return episodesToReturn;
    }
}
