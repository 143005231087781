<div class="participant-detail-component">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Participant Detail</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table class="table">
                <tbody>
                    <tr>
                        <td>Id</td>
                        <td>{{ participant.id }}</td>
                    </tr>
                    <tr>
                        <td>Demo</td>
                        <td>{{ participant.age }} {{ participant.ethnicity }} {{ participant.sex }}</td>
                    </tr>
                    <tr>
                        <td>Project</td>
                        <td>{{ project.id }} {{project.name}}</td>
                    </tr>
                    <tr>
                        <td>Execution</td>
                        <td>{{ participantSet.id }} {{ participantSet.name }}</td>
                    </tr>
                    <tr>
                        <td>Created On</td>
                        <td>{{ participant.createdOn | date:'short' }}</td>
                    </tr>
                    <tr>
                        <td>Completed On</td>
                        <td>{{ (participant.completedOn) ? participant.completedOn : '--' }}</td>
                    </tr>
                    <tr>
                        <td>Is Valid</td>
                        <td>{{ (participant.isValid) ? 'Yes' : 'No' }}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</div>