export class CacheEnvelope<T> {
    private value: T;
    private expireTime: Date;

    constructor(private timeoutInSeconds: number) {}

    set(item: T) {
        this.value = item;
        this.expireTime = new Date(Date.now() + (this.timeoutInSeconds * 1000));
    }

    get(): T {
        return this.value;
    }

    isDirty(): boolean {
        return (Date.now() > this.expireTime.getTime());
    }
}
