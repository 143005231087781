import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { QualtricsSurveySummary } from '../models';
import { CacheEnvelope } from '../models/cache-envelope.model';

@Injectable({
    providedIn: 'root'
})
export class QualtricsService {

    private cacheIntervalInSeconds = 60;
    private cache: Map<string, any> = new Map<string, any>();

    constructor(private apiService: ApiService) {}

    getSurveys(): Observable<QualtricsSurveySummary[]> {
        const key = 'getSurveys()';
        const val = this.getCacheValue(key);

        if (val == null) {
            return this.apiService.get('/qualtrics/surveys')
                .pipe(tap(result => {
                    const cacheEnvelope: CacheEnvelope<QualtricsSurveySummary[]> = new CacheEnvelope<QualtricsSurveySummary[]>(
                        this.cacheIntervalInSeconds
                    );
                    cacheEnvelope.set(result);
                    this.setCacheValue(key, cacheEnvelope);
                }));
        } else {
            return of(val);
        }
    }

    private setCacheValue(key: string, value: any) {
        this.cache.set(key, value);
    }

    private getCacheValue(key: string): any {
        if (!this.cache.has(key)) {
            return null;
        }

        const cacheVal = this.cache.get(key);

        if (cacheVal.isDirty()) {
            return null;
        }

        return cacheVal.get();
    }
}
