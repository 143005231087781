import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

(async () => {
    const response = await fetch('./assets/config/ApiConfig.json');
    const json = await response.json();
    Object.entries(json).forEach(([key, value]) => {
        environment[key] = value;
    });
  
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
})();
