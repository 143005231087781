import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {

    private defaultOptions = {
        headers: { "Content-Type": "application/json" }
    };

    constructor(private _httpClient: HttpClient) { }

    private formatErrors(error: HttpErrorResponse) {
        return throwError(error.error);
    }

    get(path: string): Observable<any> {
        return this._httpClient.get(`${environment.apiRoot}${path}`, this.defaultOptions)
            .pipe(catchError(this.formatErrors));
    }

    patch(path: string, body: Object = null): Observable<any> {
        console.log('before');
        return this._httpClient.patch(`${environment.apiRoot}${path}`, JSON.stringify(body), this.defaultOptions)
            .pipe(map(() => {
                console.log('after');
            }));
    }

    put(path: string, body: Object = {}): Observable<any> {
        return this._httpClient.put(
            `${environment.apiRoot}${path}`,
            JSON.stringify(body), this.defaultOptions
        ).pipe(catchError(this.formatErrors));
    }

    post(path: string, body: Object = {}, options: Object = null): Observable<any> {
        return this._httpClient.post(
            `${environment.apiRoot}${path}`,
            JSON.stringify(body),
            (options) ? options : this.defaultOptions
        ).pipe(catchError(this.formatErrors));
    }

    delete(path: string): Observable<any> {
        return this._httpClient.delete(`${environment.apiRoot}${path}`, this.defaultOptions)
            .pipe(catchError(this.formatErrors));
    }

    download(path: string, body: Object): Observable<HttpResponse<Blob>> {
        const fullOptions = Object.assign({}, this.defaultOptions, { observe: 'response' });
        return this._httpClient.post<Blob>(`${environment.apiRoot}${path}`, JSON.stringify(body), {
            headers: { "Content-Type": "application/json" },
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }
}
