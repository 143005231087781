import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

//import { StoreModule } from '@ngrx/store';
//import { EffectsModule } from '@ngrx/effects';
//import { RouterStateSerializer } from '@ngrx/router-store';
/*import { StoreDevtoolsModule } from '@ngrx/store-devtools';*/
import { MaterialModule } from './shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

//import { AppStateModule } from './state/app-state.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp, AppInitializerService } from './core/services';
import { environment } from '@environments/environment';

//import { AuthInterceptor } from './core/interceptors/auth.interceptor';
//import { AuthModule } from './auth/auth.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        /*AppStateModule,*/
        /*StoreModule.forRoot({}),*/
        /*EffectsModule.forRoot([]),*/
        /*environment.development ? StoreDevtoolsModule.instrument() : [],*/
        HttpClientModule,
        MaterialModule,
        SidebarModule,
        NavbarModule,
        CoreModule,
        SharedModule,
        FlexLayoutModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent
    ],
    providers: [
        AppInitializerService,
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitializerService], multi: true },

        {
            provide: environment,
            useFactory: () => ({
                apiRootUrl: environment.apiRoot,
               
            })
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
