import { Component, OnInit, ViewChild } from '@angular/core';

import { NavbarComponent } from '../../shared/navbar/navbar.component';

@Component({
    selector: 'app-layout',
    templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent implements OnInit {

    @ViewChild('sidebar', { static: true }) sidebar: any;
    @ViewChild(NavbarComponent, { static: true }) navbar: NavbarComponent;

    constructor() { }

    ngOnInit() {
    }

}
