import { Subject } from 'rxjs';

import { User } from '../models';

export class UserEventEmitter extends Subject<User> {
    constructor() {
        super();
    }

    emit(value: User) { super.next(value); }
}
