import { BehaviorSubject } from 'rxjs';

import { DialDataUpdatedEvent } from '../events';

export class DialDataUpdatedEventEmitter extends BehaviorSubject<DialDataUpdatedEvent> {
    constructor(initialValue: DialDataUpdatedEvent) {
        super(initialValue);
    }

    emit(value: DialDataUpdatedEvent) { super.next(value); }
}
