import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Quota } from '@app/core/models';

@Component({
    selector: 'app-quota-list',
    templateUrl: './quota-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuotaListComponent {
    @Input() quotas: Quota[];
}
