import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mediaLength' })
export class MediaLengthPipe implements PipeTransform {
    transform(lengthInSeconds: number): string {
        const hours = Math.floor(lengthInSeconds / 3600);
        const minutes = Math.floor((lengthInSeconds - (hours * 3600))/60);
        const seconds = lengthInSeconds - (hours * 3600) - (minutes * 60);

        if (hours) {
            return hours.toString().padStart(2,'0') + ':' + minutes.toString().padStart(2,'0') + ':' + seconds.toString().padStart(2,'0');
        }

        return minutes.toString().padStart(2,'0') + ':' + seconds.toString().padStart(2,'0');
    }
}