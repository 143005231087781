import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { JwtService } from '../services/jwt.service';
import { PubSubService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private jwtService: JwtService, private pubSubService: PubSubService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headersConfig = {
            /*'Content-Type': 'application/json',*/
            'Accept': 'application/json'
        };

        const token = this.jwtService.getToken();

        if (token) {
            headersConfig['Authorization'] = `Bearer ${token}`;
        }

        const request = req.clone({ setHeaders: headersConfig });

        return next.handle(request)
            .pipe(
                tap(
                    event => {},
                    error => {
                        var respError = error as HttpErrorResponse;
                        if (respError && (respError.status === 401 || respError.status === 403)) {
                            this.router.navigate(['/pages/login']);
                        }
                    }
                ),
                finalize(() => {

                })
            );
    }
}
