export enum Roles {
    Admin = 'Admin',
    User = 'User',
    Monitor = 'Monitor',
    ResultsViewer = 'ResultsViewer',
    ResultsEditor = 'ResultsEditor',
    ProjectViewer = 'ProjectViewer',
    ProjectEditor = 'ProjectEditor',
    FacilityViewer = 'FacilityViewer',
    FacilityEditor = 'FacilityEditor',
    Super = 'Super'
}