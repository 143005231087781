import { Injectable } from '@angular/core';

import { OptionItem } from '../models';

@Injectable({ providedIn: 'root' })
export class OptionsListService {
    getGenders(): OptionItem[] {
        return [
            { id: 1, name: 'All' },
            { id: 2, name: 'Male' },
            { id: 3, name: 'Female' }
        ];
    }

    getGenres(): OptionItem[] {
        return [
            { id: 1, name: 'Drama' },
            { id: 2, name: 'Comedy' }
        ];
    }

    getProductionPhases(): OptionItem[] {
        return [
            { id: 1, name: 'Rough Cut' },
            { id: 2, name: 'Final Cut' }
        ];
    }

    getDevelopmentPhases(): OptionItem[] {
        return [
            { id: 1, name: 'Pilot' },
            { id: 2, name: 'Episode' }
        ];
    }

    getCharacterTypes(): OptionItem[] {
        return [
            { id: 1, name: 'Lead' },
            { id: 2, name: 'Support' }
        ];
    }
}
