import { BehaviorSubject } from 'rxjs';

import { ProjectSelectedEvent } from '../events';

export class ProjectSelectedEventEmitter extends BehaviorSubject<ProjectSelectedEvent> {
    constructor(initialValue: ProjectSelectedEvent) {
        super(initialValue);
    }

    emit(value: ProjectSelectedEvent) { super.next(value); }
}
