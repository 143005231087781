import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { PubSubService } from 'src/app/core/services';
import { Router, Event as NavigationEvent, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnDestroy {
    private subs: SubSink = new SubSink();

    loading$: BehaviorSubject<boolean>;

    constructor(private pubSubService: PubSubService, private router: Router) {
        this.loading$ = this.pubSubService.IsLoadingChannel$;
        this.monitorNavigation();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    private monitorNavigation() {
        this.subs.sink = this.router.events
            .pipe(
                filter((event: NavigationEvent) => (event instanceof NavigationStart || event instanceof NavigationEnd))
            )
            .subscribe((event: NavigationEvent) => {
                if (event instanceof NavigationStart) {
                    this.loading$.next(true);
                } else {
                    this.loading$.next(false);
                }
            });
    }
}
