import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ListErrorsComponent } from './list-errors.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MaterialModule } from './material.module';
import {
    ProjectListComponent,
    ParticipantDetailComponent,
    ParticipantScreenerListComponent,
    QuotaListComponent,
    TerminationFormulaListComponent,
    TerminationReasonDisplayComponent
} from '../shared/components';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        MaterialModule
    ],
    declarations: [
        ListErrorsComponent,
        SpinnerComponent,
        ConfirmationDialogComponent,
        ProjectListComponent,
        ParticipantDetailComponent,
        ParticipantScreenerListComponent,
        QuotaListComponent,
        TerminationFormulaListComponent,
        TerminationReasonDisplayComponent
    ],
    entryComponents: [
        ConfirmationDialogComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ListErrorsComponent,
        RouterModule,
        SpinnerComponent,
        ProjectListComponent,
        ParticipantDetailComponent,
        ParticipantScreenerListComponent,
        QuotaListComponent,
        TerminationFormulaListComponent,
        TerminationReasonDisplayComponent
    ]
})
export class SharedModule { }
