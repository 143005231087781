import { Subject } from 'rxjs';

import { Project } from '../models';

export class ProjectEventEmitter extends Subject<Project> {
    constructor() {
        super();
    }

    emit(value: Project) { super.next(value); }
}
