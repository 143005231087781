import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Participant, ParticipantSet, Project } from '@app/core/models';

import * as moment from 'moment';

@Component({
    selector: 'app-participant-detail',
    templateUrl: './participant-detail.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipantDetailComponent {
    @Input() participant: Participant;
    @Input() project: Project;
    @Input() participantSet: ParticipantSet;
}
