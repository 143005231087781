import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ParticipantScreener } from '@app/core/models';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-participant-screener-list',
    templateUrl: './participant-screener-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipantScreenerListComponent {

    private _participantScreeners: ParticipantScreener[] = [];

    constructor() {
        this.screenerObjects$ = new BehaviorSubject<any[]>([]);
    }

    get participantScreeners() {
        return this._participantScreeners;
    }

    @Input()
    set participantScreeners(participantScreeners: ParticipantScreener[]) {
        this._participantScreeners = participantScreeners;
        this.screenerObjects$.next(this._participantScreeners.map(screener => JSON.parse(screener.screenerData)));
    }

    screenerObjects$: BehaviorSubject<any[]>;
}
