import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './interceptors/auth.interceptor';

import { ApiService } from './services/api.service';
import { JwtService } from './services/jwt.service';
import { UserService } from './services/user.service';
import { AuthGuardService } from './services/auth-guard.service';

import {
    BooleanToYesNoPipe,
    MediaLengthPipe,
    OrderByPipe
} from './pipes';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        BooleanToYesNoPipe,
        MediaLengthPipe,
        OrderByPipe
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ApiService,
        JwtService,
        UserService,
        AuthGuardService
    ],
    exports: [
        BooleanToYesNoPipe,
        MediaLengthPipe,
        OrderByPipe
    ]
})
export class CoreModule { }
