import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router, Data } from '@angular/router';

import { Observable, of } from 'rxjs';

import { UserService } from './user.service';
import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const currentUser = this.userService.getCurrentUser();
        if (currentUser && this.userCanActivateRoute(currentUser, route.data)) {
            return of(true);
        }
        
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/pages', 'login'], { queryParams: { returnUrl: state.url } });
        return of(false);
    }

    ///////////////////////////////////

    private userCanActivateRoute(currentUser: User, routeData: Data): boolean {
        if (!(currentUser.hasOwnProperty('roles') && currentUser.roles.length)) {
            return false;
        }

        // check if route is restricted by role
        if (routeData.roles && !routeData.roles.some((x: string) => currentUser.roles.includes(x))) {
            // role not authorised so redirect to home page
            this.router.navigate(['/']);
            return false;
        }

        // authorised so return true
        return true;
    }
}
