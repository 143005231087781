import { BehaviorSubject } from 'rxjs';

import { TraceItemSelectionChangedEvent } from '../events';

export class TraceItemSelectionChangedEventEmitter extends BehaviorSubject<TraceItemSelectionChangedEvent> {
    constructor(initialValue: TraceItemSelectionChangedEvent) {
        super(initialValue);
    }

    emit(value: TraceItemSelectionChangedEvent) { super.next(value); }
}
