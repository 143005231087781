import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TerminationReason } from '@app/core/models';

@Component({
    selector: 'app-termination-reason-display',
    templateUrl: './termination-reason-display.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminationReasonDisplayComponent {
    @Input() terminationReason: TerminationReason;
}
