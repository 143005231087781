import { Component, OnInit } from '@angular/core';

import { ROUTES, RouteInfo } from './sidebar.routing';
import { UserService } from '@app/core/services';
import { User } from '@app/core/models';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    public menuItems: any[];

    constructor(private userService: UserService) { }

    ngOnInit() {
        const user = this.userService.getCurrentUser();
        if (user.hasOwnProperty('roles')) {
            this.menuItems = this.getRoutesForUser(user);
        }
    }

    ///////////////////////////////////

    private getRoutesForUser(user: User): RouteInfo[] {
        let routes: RouteInfo[] = ROUTES.filter(r => {
            return r.roles.some(x => user.roles.includes(x));
        });

        return routes;
    }
}
